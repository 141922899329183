<template>
  <div class="intro">
    <van-nav-bar title="景点介绍" >
      <template #left>
        <van-icon :size="24" color="#141212" name="arrow-left" slot="left" @click="exit()" />
      </template>
    </van-nav-bar>
    <div class="content" v-html="depiction"></div>
  </div>
</template>

<script>
  import { NavBar, Icon} from 'vant'
  export default {
    name: "intro",
    components:{
      'van-icon': Icon,
      'van-nav-bar': NavBar,
    },
    data() {
      return {
        scenicKey:'',
        apis:{
          scenic:'/seller/find',//获取景点信息
        },
        depiction:'',
      }
    },
    created(){

    },
    methods: {
      exit(){
        this.$router.go(-1)
      },
      loadScenic(){//获取景点详情
        const me = this;
        me.scenic = {};
        me.imgs = [];
        me.$get({
          url:me.apis.scenic,
          params: {
            sellerId: me.scenicKey,
            token: me.$global.token
          }
        }).then(rsp => {
          me.depiction = rsp.depiction;
        });
      },
    },
    mounted() {
      const me = this;
      me.scenicKey  = me.$route.query.key;
      me.loadScenic();
    },
  }
</script>

<style scoped>

  .intro{
    font-size: 12px;
  }

</style>
